import styled from "@emotion/styled";

export const Padding = styled.div`
  padding-top: 50px;
  padding-bottom: 135px;
`;

export const WithUsTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 1.75px;
  color: #f38f1d;
  margin-bottom: 48px;
  text-transform: uppercase;
`;

export const WithUsParagraph = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 48px;
`;

export const StatementsSection = styled.div`
  padding-top: 70px;
  padding-bottom: 42px;
  margin-bottom: 115px;
  background-color: #f38f1d;
`;

export const StatementsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StatementsTitle = styled.h2`
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 87px;
`;

export const StatementsIndicator = styled.div<{ active: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  background-color: ${props => (props.active ? "black" : "white")};
`;

export const ClientsTitle = styled.h2`
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 51px;
`;

export const ClientsParagraph = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 57px;
`;

export const ClientLogo = styled.img`
  width: 143px;
  height: 143px;
  object-fit: contain;
  border: solid 1px #979797;
  margin-left: 12px;
  margin-bottom: 12px;
`;

export const VanguardWrapper = styled.div`
  overflow: auto;
  max-width: 850px;
`;

export const VanguardBar = styled.div`
  width: 100%;
  height: 15px;
  background-color: #f38f1d;
`;

export const VanguardTitle = styled.h2`
  font-family: Roboto;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #f38f1d;
  text-transform: uppercase;
  text-align: right;
  margin-top: 11px;
`;

export const VanguardParagraph = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
`;

export const ManufactorsTitle = styled.h2`
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 51px;
`;

const styles = {
  Padding,
  WithUsTitle,
  WithUsParagraph,
  StatementsTitle,
  StatementsSection,
  StatementsRow,
  StatementsIndicator,
  ClientsTitle,
  ClientsParagraph,
  ClientLogo,
  VanguardBar,
  VanguardTitle,
  VanguardParagraph,
  VanguardWrapper,
  ManufactorsTitle
};

export default styles;
