import { Container, Row, Col, Visible } from "react-grid-system";
import React, { FunctionComponent, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import SwipeableViews from "react-swipeable-views";
import { useIntl } from "react-intl";
import { graphql } from "gatsby";
import { chunk } from "lodash";

import Preview from "../components/common/Preview";
import Statement from "../components/Statement";
import { Layout } from "../components";

import {
  Padding,
  WithUsTitle,
  WithUsParagraph,
  StatementsTitle,
  StatementsSection,
  StatementsRow,
  StatementsIndicator,
  ClientsTitle,
  ClientsParagraph,
  ClientLogo,
  // VanguardBar,
  // VanguardTitle,
  // VanguardParagraph,
  // VanguardWrapper,
  ManufactorsTitle
} from "../styles/with-us";

interface Statement {
  name: { text: string };
  occupation: { text: string };
  quote: { html: string };
}

interface WithUsPageProps extends RouteComponentProps {
  data: {
    prismicWithUsPage: {
      data: {
        page_paragraph: { html: string };
        statements: Statement[];
        clients_paragraph: { html: string };
        clients_logos: Array<{
          brand_logo: {
            url: string;
            alt: string;
          };
        }>;
        partnerships_paragraph: { html: string };
        vanguard_paragraph: { html: string };
        manufactors_logos: Array<{
          brand_logo: {
            url: string;
            alt: string;
          };
        }>;
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
      };
    };
  };
}

const WithUsPage: FunctionComponent<WithUsPageProps> = ({ data }) => {
  const { formatMessage } = useIntl();
  const [currStatementIndex, setCurrStatementIndex] = useState<number>(0);

  const handleSelectIndicator = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selectedIndex: number
  ) => {
    e?.preventDefault();
    setCurrStatementIndex(selectedIndex);
  };

  const { statements } = data.prismicWithUsPage.data;
  const allStatements: { [chunk: string]: Statement[][] } = {};
  for (let k = 0; k < 3; k++) {
    const maxStatements = k + 1;
    allStatements[`${maxStatements}`] = chunk(statements, maxStatements);
  }

  const clientLogos = data.prismicWithUsPage.data.clients_logos.map(
    (clientLogo, index) => (
      <ClientLogo
        key={`cLogo#${index}`}
        alt={clientLogo.brand_logo.alt}
        src={clientLogo.brand_logo.url}
      />
    )
  )

  const manufactorLogos = data.prismicWithUsPage.data.manufactors_logos.map(
    (manufactorLogo, index) => (
      <ClientLogo
        key={`manufactor#${index}`}
        alt={manufactorLogo.brand_logo.alt}
        src={manufactorLogo.brand_logo.url}
      />
    )
  )

  return (
    <Layout
      underline="with-us"
      metaTitle={
        data.prismicWithUsPage.data.title ||
        formatMessage({ id: "pages.with-us.meta-title" })
      }
      metaDescription={
        data.prismicWithUsPage.data.description ||
        formatMessage({ id: "pages.with-us.meta-description" })
      }
      metaKeywords={
        data.prismicWithUsPage.data.keywords ||
        formatMessage({ id: "pages.with-us.meta-Keywords" })
      }
      metaURL={data.prismicWithUsPage.data.canonical_url?.url}
      metaImage={data.prismicWithUsPage.data.image}
    >
      <Padding>
        {/* Intro Sections */}
        <Container>
          <Row>
            <Col xs={12}>
              <Visible xs sm>
                <WithUsTitle style={{ textAlign: "center" }}>
                  {formatMessage({ id: "with-us" })}
                </WithUsTitle>
                <WithUsParagraph
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: data.prismicWithUsPage.data.page_paragraph.html
                  }}
                />
              </Visible>
              <Visible md lg xl>
                <WithUsTitle>{formatMessage({ id: "with-us" })}</WithUsTitle>
                <WithUsParagraph
                  dangerouslySetInnerHTML={{
                    __html: data.prismicWithUsPage.data.page_paragraph.html
                  }}
                />
              </Visible>
            </Col>
          </Row>
        </Container>
        <StatementsSection>
          <Container>
            <Row>
              <Col xs={12}>
                <StatementsTitle>
                  {formatMessage({ id: "statements" })}
                </StatementsTitle>
              </Col>
            </Row>
            <Row>
              <Visible lg xl>
                <SwipeableViews
                  style={{ width: "100%" }}
                  index={currStatementIndex}
                  onChangeIndex={index => setCurrStatementIndex(index)}
                >
                  {allStatements["3"].map((statementsChunk, cIndex) => (
                    <StatementsRow key={`chunk#${cIndex}`}>
                      {statementsChunk.map((statement, sIndex) => (
                        <Statement
                          name={statement.name.text}
                          content={statement.quote.html}
                          occupation={statement.occupation.text}
                          key={`statement${cIndex * sIndex + sIndex}`}
                        />
                      ))}
                    </StatementsRow>
                  ))}
                </SwipeableViews>
              </Visible>
              <Visible md>
                <SwipeableViews
                  index={currStatementIndex}
                  onChangeIndex={index => setCurrStatementIndex(index)}
                >
                  {allStatements["2"].map((statementsChunk, cIndex) => (
                    <StatementsRow key={`chunk#${cIndex}`}>
                      {statementsChunk.map((statement, sIndex) => (
                        <Statement
                          name={statement.name.text}
                          content={statement.quote.html}
                          occupation={statement.occupation.text}
                          key={`statement${cIndex * sIndex + sIndex}`}
                        />
                      ))}
                    </StatementsRow>
                  ))}
                </SwipeableViews>
              </Visible>
              <Visible xs sm>
                <SwipeableViews
                  index={currStatementIndex}
                  onChangeIndex={index => setCurrStatementIndex(index)}
                >
                  {allStatements["1"].map((statementsChunk, cIndex) => (
                    <StatementsRow
                      key={`chunk#${cIndex}`}
                      style={{ justifyContent: "center" }}
                    >
                      {statementsChunk.map((statement, sIndex) => (
                        <Statement
                          name={statement.name.text}
                          content={statement.quote.html}
                          occupation={statement.occupation.text}
                          key={`statement${cIndex * sIndex + sIndex}`}
                        />
                      ))}
                    </StatementsRow>
                  ))}
                </SwipeableViews>
              </Visible>
            </Row>
            <Row justify="center" style={{ marginTop: "40px" }}>
              <Visible lg xl>
                {allStatements["3"].map((_, index) => (
                  <a
                    key={`indicator#${index}`}
                    onClick={e => handleSelectIndicator(e, index)}
                    style={{ cursor: "pointer", marginRight: "17px" }}
                  >
                    <StatementsIndicator
                      active={index === currStatementIndex}
                    />
                  </a>
                ))}
              </Visible>
              <Visible md>
                {allStatements["2"].map((_, index) => (
                  <a
                    key={`indicator#${index}`}
                    onClick={e => handleSelectIndicator(e, index)}
                    style={{ cursor: "pointer", marginRight: "17px" }}
                  >
                    <StatementsIndicator
                      active={index === currStatementIndex}
                    />
                  </a>
                ))}
              </Visible>
              <Visible xs sm>
                {allStatements["1"].map((_, index) => (
                  <a
                    key={`indicator#${index}`}
                    onClick={e => handleSelectIndicator(e, index)}
                    style={{ cursor: "pointer", marginRight: "17px" }}
                  >
                    <StatementsIndicator
                      active={index === currStatementIndex}
                    />
                  </a>
                ))}
              </Visible>
            </Row>
          </Container>
        </StatementsSection>
        {/* Customers */}
        <Container style={{ marginBottom: "135px" }}>
          <Row>
            <Col xs={12}>
              <Visible xs sm>
                <ClientsTitle style={{ textAlign: "center" }}>
                  {formatMessage({ id: "clients" })}
                </ClientsTitle>
                <ClientsParagraph
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: data.prismicWithUsPage.data.clients_paragraph.html
                  }}
                />
              </Visible>
              <Visible md lg xl>
                <ClientsTitle>{formatMessage({ id: "clients" })}</ClientsTitle>
                <ClientsParagraph
                  dangerouslySetInnerHTML={{
                    __html: data.prismicWithUsPage.data.clients_paragraph.html
                  }}
                />
              </Visible>
            </Col>
          </Row>
          <Row>
            <Col xs={10} offset={{ xs: 1 }}>
              <Visible xs sm><Row justify="center">{clientLogos}</Row></Visible>
              <Visible md lg xl><Row>{clientLogos}</Row></Visible>
            </Col>
          </Row>
        </Container>
        {/* Partners */}
        {/* <Container style={{ marginBottom: "190px" }}>
          <Row>
            <Col xs={12}>
              <Visible xs sm>
                <ClientsTitle style={{ textAlign: "center" }}>
                  {formatMessage({ id: "partnerships" })}
                </ClientsTitle>
                <ClientsParagraph
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicWithUsPage.data.partnerships_paragraph.html
                  }}
                />
              </Visible>
              <Visible md lg xl>
                <ClientsTitle>
                  {formatMessage({ id: "partnerships" })}
                </ClientsTitle>
                <ClientsParagraph
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicWithUsPage.data.partnerships_paragraph.html
                  }}
                />
              </Visible>
            </Col>
          </Row>
        </Container> */}
        {/* <VanguardWrapper>
          <div style={{ maxWidth: "526px" }}>
            <VanguardBar />
            <VanguardTitle>
              {formatMessage({ id: "tech-vanguard" })}
            </VanguardTitle>
          </div>
          <div style={{ float: "right", maxWidth: "702px", padding: "0 15px" }}>
            <VanguardParagraph
              dangerouslySetInnerHTML={{
                __html: data.prismicWithUsPage.data.vanguard_paragraph.html
              }}
            />
          </div>
        </VanguardWrapper> */}
        {/* Manufacturers */}
        <Container style={{ marginTop: "100px" }}>
          <Row>
            <Col xs={12}>
              <Visible xs sm>
                <ManufactorsTitle style={{ textAlign: "center" }}>
                  {formatMessage({ id: "manufacturers" })}
                </ManufactorsTitle>
                <ClientsParagraph
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicWithUsPage.data.partnerships_paragraph.html
                  }}
                />
              </Visible>
              <Visible md lg xl>
                <ManufactorsTitle>
                  {formatMessage({ id: "manufacturers" })}
                </ManufactorsTitle>
                <ClientsParagraph
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicWithUsPage.data.partnerships_paragraph.html
                  }}
                />
              </Visible>
            </Col>
          </Row>
          <Row>
            <Col xs={10} offset={{ xs: 1 }}>
              <Visible xs sm><Row justify="center">{manufactorLogos}</Row></Visible>
              <Visible md lg xl><Row>{manufactorLogos}</Row></Visible>
            </Col>
          </Row>
        </Container>
      </Padding>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicWithUsPage(lang: { eq: $lang }) {
      data {
        page_paragraph {
          html
        }
        statements {
          name {
            text
          }
          occupation {
            text
          }
          quote {
            html
          }
        }
        clients_paragraph {
          html
        }
        clients_logos {
          brand_logo {
            url
            alt
          }
        }
        partnerships_paragraph {
          html
        }
        vanguard_paragraph {
          html
        }
        manufactors_logos {
          brand_logo {
            url
            alt
          }
        }
        title
        description
        keywords
        canonical_url {
          link_type
          url
          target
        }
        image {
          alt
          copyright
          url
        }
      }
    }
  }
`;

export default Preview(WithUsPage);
