import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";

import styles from "./styles";

interface StatementProps {
  name: string;
  occupation: string;
  content: string;
}

const Statement: FunctionComponent<StatementProps> = ({
  name,
  occupation,
  content
}) => {
  const {
    Wrapper,
    Filedset,
    StatementName,
    StatementContent,
    StatementOccupation,
    QuoteIconWrapper
  } = styles;
  const querydata = useStaticQuery(graphql`
    query {
      quote: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "icons/right-quote-sign.svg" }
      ) {
        publicURL
      }
    }
  `);

  return (
    <Wrapper>
      <Filedset>
        <StatementName>{name}</StatementName>
        <StatementOccupation>{occupation}</StatementOccupation>
        <StatementContent dangerouslySetInnerHTML={{ __html: content }} />
      </Filedset>
      <QuoteIconWrapper>
        <img
          src={querydata.quote.publicURL}
          alt="Right Quote Sign"
          width="45"
          height="45"
        />
      </QuoteIconWrapper>
    </Wrapper>
  );
};

export default Statement;
