import styled from "@emotion/styled";

const Wrapper = styled.div`
  max-width: 276px;
  position: relative;
`;

const Filedset = styled.fieldset`
  margin-bottom: 80px;
  border: solid 3px #ffffff;
`;

const StatementName = styled.legend`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.19px;
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
`;

const StatementOccupation = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.15px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
`;

const StatementContent = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 80px;
`;

const QuoteIconWrapper = styled.div`
  right: 20px;
  bottom: 26px;
  width: 107px;
  height: 107px;
  border-radius: 53.5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const styles = {
  Wrapper,
  Filedset,
  StatementName,
  StatementContent,
  StatementOccupation,
  QuoteIconWrapper
};

export default styles;
